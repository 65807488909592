.Button {
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .3), inset 0px 4px 1px 1px white, inset 0px -2px 1px 1px rgba(204,198,197,.5);
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}
.RecordButton {
  composes: Button;
  background-image: url('./images/record.png');
}
.PlayButton {
  composes: Button;
  background-image: url('./images/play.png');
}
.StopButton {
  composes: Button;
  background-image: url('./images/stop.png');
}
.DownloadButton {
  composes: Button;
  background-image: url('./images/download.png');
}
.SettingsButton {
  composes: Button;
  background-image: url('./images/settings.png');
}
.Hidden {
  display: none;
}
.VideoModal {
  position: absolute;
  background: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;
  top: 20%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .3), inset 0px 4px 1px 1px white, inset 0px -2px 1px 1px rgba(204,198,197,.5);
  left: calc(50% - 360px);
  video {
    width: 720px;
  }
  button {
    position: absolute;
    right: 25px;
    top: 2px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-weight: 700;
  }
}
